<template>
<div class="main home">
    <div class="home-box">
        <h1>用户管理</h1>
        <div class="home-content">
            <search-item :searchInfo.sync="info" @searchList="searchList" @addYH="addYH"></search-item>
            <!-- 表格 -->
            <div class="table_container" style="margin-top:10px;">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <el-table-column prop="operatorName" label="用户名称" align='center'>
                    </el-table-column>
                    <el-table-column prop="account" label="登录账号" align='center'>
                    </el-table-column>
                    <el-table-column prop="operatorTel" label="手机号" align='center'>
                    </el-table-column>
                    <el-table-column prop="enprNo" label="水司编号" align='center'>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="handleUpdate(scope.row)" type="text" size="small">修改用户信息</el-button>
                            <el-button @click="handleUpdatePwd(scope.row)" type="text" size="small">修改密码</el-button>
                            <el-button type="text" :disabled="scope.row.account == 'admin'" size="small" @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
        </div>
    </div>
    <el-dialog :modal-append-to-body='false' title="添加/修改用户信息" :visible.sync="showDialog" width="27%">
        <el-form ref="form" label-width="80px">
            <el-form-item v-if="!editPwdState" label="用户名称" required>
                <el-input v-model="yhData.operatorName"></el-input>
            </el-form-item>
            <el-form-item v-if="!editPwdState" label="手机号" required>
                <el-input :maxlength="11" v-model="yhData.operatorTel"></el-input>
            </el-form-item>
            <el-form-item v-if="!editState" label="登录账号" required>
                <el-input :disabled="editPwdState" v-model="yhData.account"></el-input>
            </el-form-item>
            <el-form-item v-if="!editState" label="密码" required>
                <el-input v-model="yhData.password">
                    <el-button slot="append" type="primary" @click="generatePwd">随机生成</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="水司选择">
                <el-select style="width:100%;" v-model="yhData.enprNo" filterable clearable placeholder="请选择水司">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.enprNo">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="!editState && !editPwdState" label="账号类型" required>
                <el-radio v-model="yhData.operatorType" :label="0">生产账户</el-radio>
                <el-radio v-model="yhData.operatorType" :label="1">管理员账户</el-radio>
                <el-radio v-model="yhData.operatorType" :label="2">JWX查询账户</el-radio>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" round @click="onSubmit">提交</el-button>
                <el-button round @click="close">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
const phoneReg = /^(((13[0-9]{1})|(15[0-9]{1})|(14[0-9]{1})|(16[0-9]{1})|(92[0-9]{1})|(98[0-9]{1})|(18[0-9]{1})|(19[0-9]{1})|(17[0-9]{1}))+\d{8})$/
export default {
    data() {
        return {
            // 表格loading
            tableLoading: true,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 0, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            centerDialogVisible: false,
            registerId: "",
            info: {
                operatorName: "",
                operatorTel: ""
            },
            activeName: '0',
            showDialog: false,
            yhData: {
                operatorName: '',
                operatorTel: '',
                account: '',
                password: '',
                operatorType: 1,
                enprNo: ''
            },
            editState: false,
            editPwdState: false,
            roleOptions: [],
            roleValue: '',
            arrEnpr: []
        }
    },
    mounted() {
        let that = this
        this.getAllEnpr()
        this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
    },
    methods: {
         //获取所有水司
         getAllEnpr() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        //  获取表格信息
        getYHList(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                pageNumber: currpage,
                pageSize: pageSize,
                operatorName: info ? info.operatorName : '',
                operatorTel: info ? info.operatorTel : ''
            }
            this.tableData = []
            console.log(params)
            this.$api.getAdminYHList(params).then(res => {
                console.log('000', res)
                this.tableLoading = false;
                if (res && res.code == "0") {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData = res.data.list
                } else {
                    this.tableLoading = false;
                    this.$message.error(res.errorMsg)
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getYHList(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        submitDialog(e) {
            this.registerId = e.registerId
            this.centerDialogVisible = true
        },
        onSubmit() {
            let that = this
            if (!this.editPwdState) {
                if (this.yhData.operatorName.toString().trim() == "") {
                    this.$message.warning('请输入用户名称')
                    return
                }
                if (this.yhData.operatorTel.toString().trim() == "") {
                    this.$message.warning('请输入用户手机号')
                    return
                }
                if (!phoneReg.test(this.yhData.operatorTel)) {
                    this.$message.warning('请输入正确格式的手机号')
                    return
                }
            }
            if (!this.editState) {
                if (this.yhData.account.toString().trim() == "") {
                    this.$message.warning('请填写用户账号')
                    return
                }
                if (this.yhData.password.toString().trim() == "") {
                    this.$message.warning('请填写用户密码')
                    return
                }
            }
            let params = {}
            if (this.editState) {
                this.$api.updateYH(this.yhData).then(res => {
                    console.log(res)
                    if (res.code == "0") {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.showDialog = false
                        this.editState = false
                        this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.errorMsg)
                    }
                })
            } else if (this.editPwdState) {
                this.$api.updateYHPwd(this.yhData).then(res => {
                    console.log(res)
                    if (res.code == "0") {
                        this.$message({
                            message: '修改密码成功',
                            type: 'success'
                        });
                        this.showDialog = false
                        this.editState = false
                        this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.errorMsg)
                    }
                })
            } else {
                params = this.yhData
                this.$api.saveYH(params).then(res => {
                    console.log(res)
                    if (res.code == "0") {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.showDialog = false
                        this.editState = false
                        this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.errorMsg)
                    }
                })
            }
        },
        handleUpdate(e) {
            console.log(e)
            this.showDialog = true
            this.yhData = {
                operatorId: e.operatorId,
                operatorName: e.operatorName,
                operatorTel: e.operatorTel,
                operatorType: 0,
                enrpNo: ''
            }
            this.editPwdState = false
            this.editState = true
        },
        handleUpdatePwd(e) {
            console.log(e)
            this.showDialog = true
            this.yhData = {
                operatorId: e.operatorId,
                account: e.account,
                password: ''
            }
            this.editState = false
            this.editPwdState = true
        },
        addYH() {
            this.yhData = {
                operatorName: '',
                operatorTel: '',
                account: '',
                password: '',
                operatorType: 0,
                enprNo: ''
            }
            this.editState = false
            this.editPwdState = false
            this.showDialog = true
        },
        close() {
            this.showDialog = false
        },
        del(e) {
            let that = this
            this.$confirm('确定要删除该用户吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.delYH({
                    operatorId: e.operatorId
                }).then(res => {
                    if (res.code == "0") {
                        this.getYHList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        generatePwd() {
            this.yhData.password = this.$Fn.RandomPassword(8)
            this.$forceUpdate()
            console.log(this.yhData)
        }
    },
    components: {
        Pagination,
        SearchItem
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .operatorName-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.rs-block {
    width: 98%;
    height: 80px;
    text-align: right;
    line-height: 80px;
}
</style>
