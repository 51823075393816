<template>
<div class="in-report-manage">
    <component :is="views"></component>
</div>
</template>

<script>
import yhList from '@/views/YHGL/components/yhList';
export default {
    data() {
        return {
            views: yhList
        }
    },
    methods: {},
    mounted() {}
}
</script>
